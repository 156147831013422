/* Main styling for the HomeGod component */
.home-god-container {
  z-index: 5;
  position: relative;
  text-transform: none;
}

/* Floating Feedback Button Styling */
.floating-feedback {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4377e9;
  border-radius: 50%;
  cursor: pointer;

  box-shadow: 0 15px 15px rgba(2, 47, 245, 0.2);
  transition: transform 0.3s ease, width 0.3s ease;
  overflow: hidden;
}

.floating-feedback img.feedback-logo {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

/* On hover, expand the button to show text */
.floating-feedback {
  width: 130px;
  border-radius: 25px;
}

.floating-feedback span {
  /* display: none; */
  font-size: 11px;
  margin-left: 8px;
}
