/* General styles */
.signin-container {
  margin: 1rem;
  background-color: #948e8e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* height: 100vh; */
  /* background-color: #f8f9fa; */
  margin-bottom: 20px;
}

.signin-form {
  width: 90vw; /* Adjusted for mobile view */
  max-width: 400px; /* Limit width on larger screens */
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin: 0 auto; /* Center align horizontally on larger screens */
}

.signin-title {
  font-size: 2rem;
  color: #32cd32;
  text-align: center;
  margin-bottom: 1rem;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Inset shadow for input fields */
}

.toggle-password-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #6c757d;
}

.btn-primary {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #32cd32;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for button */
}

.btn-primary:hover {
  background-color: #214b21;
}

a {
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

/* Media queries for responsive design */

@media (min-width: 768px) {
  .signin-form {
    width: 60%; /* Adjusted width for desktop */
  }
}
