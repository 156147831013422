/* Sign-Up Page Container */
.sign-up-container {
  width: 100%;
  padding: 2rem;
  background-color: #f4f4f4;
}

/* Form Container */
.form-container {
  background-color: #ffffff;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  color: #0056b3;
}

/* Title */
.form-title h1 {
  color: #0056b3;
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
}

/* Input Fields */
.input-field {
  position: relative;
  margin-bottom: 1.5rem;
}
.input-field label {
  background-color: blue;
}

.input-field input,
.input-field textarea,
.input-field select {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.input-field input:focus,
.input-field textarea:focus,
.input-field select:focus {
  outline: none;
  border-color: #007bff;
}

/* Labels */
.input-field label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  font-size: 0.9rem;
  color: #333;
}

/* File Upload Section */
.file-upload-section {
  margin-bottom: 1.5rem;
}

.note {
  font-size: 0.9rem;
  color: #888;
}

.loading-text {
  color: #007bff;
  font-size: 1rem;
  margin-top: 0.5rem;
}

/* Category Select */
.category-select {
  margin-bottom: 1.5rem;
}

.category-select select {
  width: 100%;

  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Submit Button */
.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  border: none;

  cursor: pointer;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Note: You can add more global styles like padding, margins, and font styles based on your theme */
