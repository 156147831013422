/* Footer Styles */
#contact-section {
  background-color: #f8f8f8; /* Light background */
  padding: 40px 20px; /* Padding around the footer */
  border-top: 1px solid #eaeaea; /* Subtle top border */
}

.footer-container {
  max-width: 1200px; /* Max width for the footer */
  margin: 0 auto; /* Center the footer */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align items */
}

.footer-links {
  display: flex; /* Flex container for links */
  justify-content: space-around; /* Space between links */
  width: 100%; /* Full width */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  margin-bottom: 20px; /* Space below links */
}

.link-to-footer {
  flex: 1; /* Equal space for each link section */
  min-width: 200px; /* Minimum width for better responsiveness */
  text-align: center; /* Center text */
}

.link-to-footer h4 {
  margin-bottom: 15px; /* Space below heading */
  font-size: 18px; /* Heading size */
  font-weight: 600; /* Bold heading */
}

.link-to-footer ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
}

.link-to-footer li {
  margin: 5px 0; /* Space between list items */
}

.link-to-footer a {
  text-decoration: none; /* Remove underline */
  color: #333; /* Dark text color */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.link-to-footer a:hover {
  color: #007bff; /* Change color on hover */
}

.social-links {
  display: flex; /* Horizontal layout for social links */
  justify-content: center; /* Center align social links */
}

.social-links li {
  margin: 0 10px; /* Space between social icons */
}

.footer-bottom {
  text-align: center; /* Center text */
  margin-top: 20px; /* Space above bottom text */
  color: #777; /* Subtle text color */
  font-size: 14px; /* Smaller font size */
}
