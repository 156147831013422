* {
  /* background-color: #f9f9f9; */
  color: rgb(39, 37, 37);
  font-family: Arial, Helvetica, sans-serif;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  /* font-size: 5px; */
  /* color: white !important;
  background-color: black !important; */
}
