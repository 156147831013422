/* UserDashboard Styles */

.user-dashboard {
  width: 60vw;
  padding: 20px;
}

.card {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  color: white;
  padding: 12px 20px;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #ddd;
}

.card-body {
  padding: 20px;
}

.list-group-item {
  padding: 12px;
  margin-bottom: 12px;
  border: none;
}

.list-group-item h5 {
  margin-bottom: 5px;
  font-size: 1.1rem;
}

.list-group-item h6 {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.list-group-item p {
  margin-bottom: 10px;
}

.list-group-item .form-control {
  resize: none;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.list-group-item .btn-primary {
  border-radius: 8px;
}

/* Chat Bubble Styling */
.chat-bubble {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  position: relative;
}

.chat-bubble .user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  text-align: center;
  line-height: 32px;
  font-size: 0.9rem;
  position: absolute;
  top: -16px;
  left: -16px;
}

.chat-bubble .message {
  font-size: 0.95rem;
  line-height: 1.4;
}

.chat-bubble .timestamp {
  font-size: 0.8rem;
  color: #888;
}

.chat-bubble .answer-form {
  margin-top: 10px;
}

.pagination-nav {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: inline-flex;
  list-style: none;
  padding: 0;
}

.page-item {
  margin: 0 4px;
}

.page-link {
  padding: 8px 12px;
  color: #007bff;
  text-decoration: none;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
}

.page-link:hover {
  background-color: #f0f0f0;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

@media (max-width: 768px) {
  .user-dashboard {
    width: 95vw;
    padding: 20px;
  }
  .card {
    margin-bottom: 12px;
  }

  .list-group-item {
    padding: 10px;
    margin-bottom: 10px;
  }

  .pagination {
    flex-wrap: wrap;
  }
}

.questionInfo {
  padding: 5px;

  border-radius: 25px;
}

.questionsboxnew {
  background-color: rgb(150, 233, 205) !important;

  padding: 8px;

  border-radius: 15px;
}
.answerbox {
  padding: 4px;
  color: rgb(255, 255, 255);
  border-radius: 15px;
  background-color: rgb(0, 0, 0);
}
