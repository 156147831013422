/* Container for trending users section */
.trending-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa; /* Light gray background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  margin: auto;
}

.trending-grid {
  text-align: center;
  width: 100%;
}

.trending-grid h4 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

/* Container for user cards */
.trending-grid > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  padding: 10px;
}

/* Individual user card styling */
.custom-card {
  width: 220px;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border: 1px solid #ddd;
}

.custom-card:hover {
  transform: translateY(-5px);
  box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.15);
}

.custom-card p {
  font-size: 16px;
  color: #555;
  margin: 10px 0;
}

@media screen and (max-width: 768px) {
  .trending-list {
    width: 100%;
    padding: 10px;
  }
  .custom-card {
    width: 180px;
  }
}
