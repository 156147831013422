/* adminDashboard.css */

.admin-dashboard {
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 85vw;
  margin: 20px auto;
}

.admin-dashboard h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.admin-dashboard hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

.admin-dashboard .list-section {
  margin-bottom: 40px;
}

.admin-dashboard .list-section h3 {
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.admin-dashboard ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.admin-dashboard li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.admin-dashboard li span {
  flex: 1;
  text-align: left;
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.admin-dashboard button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.admin-dashboard button:hover {
  background-color: #0056b3;
}

.admin-dashboard .remove-button {
  background-color: #dc3545;
}

.admin-dashboard .remove-button:hover {
  background-color: #c82333;
}

.icon {
  margin-right: 10px;
  font-size: 16px;
}

.icon.approve {
  color: #28a745;
}

.icon.remove {
  color: #dc3545;
}

.admin-dashboard .action-buttons {
  display: flex;
  align-items: center;
}

.admin-dashboard .action-buttons button {
  margin-left: 10px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-dashboard .action-buttons .approve-button {
  background-color: #28a745;
  color: #fff;
}

.admin-dashboard .action-buttons .remove-button {
  background-color: #dc3545;
  color: #fff;
}

.admin-dashboard .action-buttons button:hover {
  opacity: 0.8;
}

.admin-dashboard .expert-list,
.admin-dashboard .approved-expert-list,
.admin-dashboard .user-list {
  margin-top: 20px;
}
