/* card.css */

.influencer_list {
  width: 100%;
  margin-bottom: 10px;
}

.influencer-card {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  align-items: center;

  background: rgb(230, 230, 230);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.influencer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.influencer-card .image-container {
  width: 80px;

  overflow: hidden;
}

.influencer-card img {
  margin-top: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.influencer-card .card-body {
  width: 100%;
  text-align: center;

  text-transform: none;
}

.influencer-card .card-title {
  /* font-size: 1.1rem; */
  font-weight: 600;
  /* margin-bottom: 0.75rem; */
  color: #2d3748;
}

.influencer-card .card-text {
  margin-bottom: 0.5rem;
  color: #4a5568;
  text-transform: none; /*Ensure text appears as written , otherwise it was showing everyting capital letter .  ***  very important*/
}

.influencer-card .rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 0.75rem;
}

.influencer-card .star {
  color: #f6e05e;
  font-size: 1.25rem;
}

.influencer-card .expertise {
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.75rem;
}

.influencer-card .email {
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 1rem;
}

.influencer-card .button-container {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  margin-top: 1rem;
}

.influencer-card .btn {
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.influencer-card .btn-primary {
  background-color: #000000;
}

.influencer-card .btn-primary:hover {
  background-color: #000000;
}

@media screen and (min-width: 700px) {
  .influencer-card .rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    margin-bottom: 0.75rem;
  }
}
