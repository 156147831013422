/* Reset any default column padding */
#expert-section .row {
  padding: 0px;
  /* margin-right: -10px; */
  /* margin-left: -10px; */
  display: flex;
  flex-wrap: wrap;
}

/* Card wrapper styles */
#expert-section .row > div {
  /* padding: 10px; */
  width: 100%;
}
/* Mobile first - 2 cards per row (for screens 900px and below) */
@media (max-width: 900px) {
  #expert-section .row > div {
    width: 50%; /* 2 cards per row */
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* Tablet - 3 cards per row */
@media (min-width: 768px) and (max-width: 899px) {
  #expert-section .row > div {
    width: 33.333333%; /* 3 cards per row */
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .categories-container {
    padding: 5px 25px;
  }
}

/* Desktop - 4 cards per row */
@media (min-width: 899px) {
  #expert-section .row > div {
    width: 25%; /* 4 cards per row */
    flex: 0 0 25%;
    max-width: 25%;
  }
  .categories-container {
    padding: 5px 25px;
  }
}

/* Large Desktop - 5 cards per row */
@media (min-width: 1200px) {
  #expert-section .row > div {
    width: 20%; /* 5 cards per row */
    flex: 0 0 20%;
    max-width: 20%;
  }

  .categories-container {
    padding: 4px px;
  }
}

/* Card styles */
.card {
  height: 100%;
  margin-bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Container for categories */
.categories-container {
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

/* Category buttons styling */
.categories-row-horizontal {
  padding: 0px;
  display: flex;
  overflow-x: auto;
  gap: 2px;
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  flex: 1;
}

.categories-row-horizontal::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.category-button {
  white-space: nowrap;
  padding: 8px 16px;
  margin: 2px;
  background-color: #000000;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.category-button.selected {
  background-color: #0073e6;
  color: white;
}

.category-button:hover {
  color: white;
  background-color: #3d76df;
  transition: background-color 0.3s ease;
}

/* Scroll arrows - visible only on desktop */
@media (min-width: 992px) {
  .scroll-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 8px;
    color: #0073e6;
    transition: color 0.2s;
  }

  .scroll-arrow:hover {
    color: #88b9eb;
  }

  .left-arrow {
    left: -15px;
  }

  .right-arrow {
    right: -15px;
  }
}

@media (max-width: 992px) {
  .scroll-arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 8px;
    color: #0073e6;
    transition: color 0.2s;
  }

  .scroll-arrow:hover {
    color: #a5bfda;
  }

  .left-arrow {
    left: -15px;
  }

  .right-arrow {
    right: -15px;
  }
}

/* //global search  */

/* Add this to your homeGod.css file */
/* Search input field styles */
.search-input {
  width: 100%; /* Full width for responsive design */
  max-width: 400px; /* Limit the max width */
  padding: 12px 20px; /* Padding for better spacing */
  margin-bottom: 20px; /* Space below the input */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 24px; /* Rounded corners */
  font-size: 16px; /* Font size */
  outline: none; /* Remove default outline */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for interactions */
}

/* Focus state for the search input */
.search-input:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.4); /* Slightly enhance shadow */
}

/* Placeholder text styles */
.search-input::placeholder {
  color: #aaa; /* Placeholder text color */
  opacity: 1; /* Full opacity */
}

/* Responsive styles for mobile */
@media (max-width: 576px) {
  .search-input {
    padding: 10px; /* Reduce padding for smaller screens */
    font-size: 14px; /* Slightly smaller font size */
    margin-bottom: 15px; /* Space below the input */
  }
}

/*
To prevent layout shifts when influencers load, you can use skeleton loaders instead of empty space. This keeps the layout stable while data loads.

Solution:
Use a Placeholder Skeleton Loader:

Show a skeleton structure (gray boxes with animated waves) where the influencer cards will appear.
Replace them with real data once it loads.
CSS for Skeleton Loader:

*/
.skeleton-card {
  width: 200px;
  height: 250px;
  background: #f0f0f0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.skeleton-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  animation: skeleton-loading 1.5s infinite linear;
}

@keyframes skeleton-loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
