/* searchbox.css */

.search-box {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.search-box input {
  width: 100%;
  padding: 12px 40px 12px 15px; /* Adjusted padding for loader space */
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 25px;
  outline: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.search-box input:focus {
  border-color: #007bff;
}

.search-box input::placeholder {
  color: #aaa;
  transition: color 0.2s ease;
}

.search-box input:focus::placeholder {
  color: #888;
}

.search-box .loader {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid transparent; /* Transparent by default */
  border-top: 2px solid #007bff; /* Visible only when loading */
  border-radius: 50%;
  visibility: hidden;
}

.search-box.loading .loader {
  visibility: visible; /* Loader visible only in loading state */
  animation: spin 0.7s linear infinite;
}

/* Spin animation for the loader */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
