/* Overall container styles for the rating */
.star-rating {
  display: flex;
  align-items: center;
  font-size: 22px; /* Smaller font size for a more compact look */
  gap: 8px; /* Reduced gap between stars */
  cursor: pointer;
  padding: 10px 0; /* Added padding for better spacing */
}

.star {
  color: #ddd; /* Gray color for unselected stars */
  transition: color 0.2s ease;
}

.star.filled {
  color: #f0cc00; /* Gold color for selected stars */
}

.star:hover {
  color: #ffcc00; /* Slightly different gold color for hover effect */
}

/* Text that displays the current rating */
.star-rating p {
  margin-left: 10px; /* Reduced left margin */
  font-size: 16px; /* Slightly smaller font size */
  color: #333; /* Darker text color for better readability */
  font-weight: 500; /* Slightly bolder text */
  margin-top: 0; /* Remove top margin */
}

/* Form elements */
.form-group {
  margin-bottom: 20px; /* Reduced space between form fields */
}

.form-control {
  width: 100%;
  padding: 10px 12px; /* More compact padding */
  font-size: 14px; /* Smaller font size for inputs */
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-top: 8px;
  background-color: #f7f7f7; /* Light background for inputs */
  transition: border-color 0.2s ease;
}

.form-control:focus {
  border-color: #3b82f6; /* Blue border on focus */
  outline: none;
}

textarea.form-control {
  height: 120px; /* Limit textarea height for a more compact look */
}

/* Submit button */
button[type="submit"] {
  background-color: #007bff; /* Blue background for submit button */
  color: white;
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Full width button on mobile */
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

button[type="submit"]:disabled {
  background-color: #ccc; /* Disabled button color */
  cursor: not-allowed;
}

/* General container max-width for desktop */
.container.main-container {
  max-width: 800px; /* Set a max width for form on larger screens */
  margin: 0 auto; /* Center the form */
  padding: 20px; /* Add padding inside the container */
  box-sizing: border-box;
}

/* Responsive styles */
@media (max-width: 768px) {
  .star-rating {
    font-size: 20px; /* Smaller font size for mobile */
    gap: 6px; /* Reduced gap */
  }

  .star-rating p {
    font-size: 14px; /* Smaller rating text */
    margin-left: 8px;
  }

  .form-control {
    font-size: 13px; /* Smaller font size for form fields */
    padding: 8px 10px; /* Compact padding */
  }

  button[type="submit"] {
    font-size: 15px; /* Smaller button text */
    padding: 8px 16px; /* Compact button size */
  }
}

/* For even smaller screens (e.g., phones) */
@media (max-width: 480px) {
  .star-rating {
    font-size: 18px; /* Further reduction for small screens */
    gap: 4px; /* Even smaller gap */
  }

  .star-rating p {
    font-size: 12px; /* Smaller rating text */
    margin-left: 6px;
  }

  .form-control {
    font-size: 12px; /* Adjust font size on very small screens */
    padding: 6px 8px; /* Further reduced padding */
  }

  button[type="submit"] {
    font-size: 14px; /* Smaller button text */
    padding: 8px 14px; /* Adjust padding for smaller buttons */
  }
}
