/* HowItWorks.css */
.how-it-works {
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #f7f8fa;
  padding: 2rem;
  animation: fadeIn 0.6s ease-in-out;
}

.section-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 1rem;
}

.how-it-works-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.intro h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
  text-align: center;
}

.intro p {
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 1.5rem;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.step {
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.step:hover {
  transform: translateY(-4px);
}

.step h3 {
  font-size: 1.25rem;
  color: #222;
  margin-bottom: 0.5rem;
}

.step p {
  font-size: 1rem;
  color: #666;
}

@media (min-width: 768px) {
  .steps {
    flex-direction: row;
    justify-content: space-between;
  }

  .step {
    width: 30%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
