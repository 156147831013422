/* General Styles */
body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

/* Main Container */
.questions-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Header */
h1 {
  color: #333;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

/* Comments List */
.comments-list {
  margin-top: 10px;
}

/* Comment Box */
.comment {
  border: 2px solid blue;
  background: #fff;
  border-radius: 12px;
  margin: 10px 0;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Comment Title */
.comment h4 {
  font-size: 1.3em;
  color: #333;
  margin-bottom: 8px;
  font-weight: bold;
}

/* Comment Details */
.comment p {
  color: #555;
  font-size: 14px;
  line-height: 1.5;
}

/* User Information */
.comment p strong {
  color: #007aff;
  font-weight: normal;
}

/* Comment Actions */
.comment-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

/* Action Buttons */
button {
  background-color: transparent;
  border: none;
  color: #657786;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: color 0.3s ease;
}

button:hover {
  color: #1da1f2; /* Twitter blue */
}

button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

button svg {
  width: 18px;
  height: 18px;
}

/* Like Button */
.like-btn {
  color: #1da1f2; /* Twitter blue */
}

.like-btn.liked {
  color: #ff5e5b; /* Red for liked */
}

.dislike-btn {
  color: #657786; /* Gray */
}

.dislike-btn.disliked {
  color: #aab8c2; /* Light gray */
}

.flag-btn {
  color: #ff9800; /* Orange for flag */
}

.reply-btn {
  color: #17bf63; /* Green for reply */
}

/* Reply Box */
.reply-box {
  margin-top: 12px;
  padding: 12px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.reply-box textarea {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
  resize: none;
  transition: border 0.3s ease;
}

.reply-box textarea:focus {
  border-color: #1da1f2; /* Twitter blue */
}

.reply-box button {
  background-color: #1da1f2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 8px;
  font-size: 14px;
}

.reply-box button:hover {
  background-color: #0a85d9; /* Darker blue */
}

/* Replies */
.reply {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  margin-top: 8px;
  font-size: 14px;
  color: #444;
}

.reply strong {
  color: #1da1f2;
}

/* Media Queries */
@media screen and (max-width: 600px) {
  .comment-actions {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  button {
    margin-right: 12px;
    font-size: 13px;
  }

  .comment-actions button svg {
    width: 16px;
    height: 16px;
  }

  .reply-box {
    margin-top: 10px;
  }

  /* Ensure replies and buttons stay neat in mobile view */
  .comment {
    padding: 12px;
  }
}
