.trending-container {
  margin: auto;
  padding: 5px;
  width: 100%;
}

.trending-button {
  cursor: pointer;
  background-color: #000000;
  color: white;
  padding: 10px 15px;
  text-align: center;
  border-radius: 5px;
  margin: 10px auto;
  display: block;
  width: fit-content;
  font-weight: bold;
}

.trending-section {
  width: 100% !important;
  text-align: center;
  padding: 0px;
}

.trending-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  padding: 10px;
}

.custom-card {
  margin-top: 0px;
  max-width: 190px;

  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.custom-card:hover {
  transform: scale(1.05);
}

.influencer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.influencer-img {
  width: 65%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.influencer-name {
  font-size: 12px;
  font-weight: bold;
  margin: 5px 0;
}

.influencer-score,
.influencer-expertise,
.influencer-rating {
  font-size: 14px;
  color: #555;
  margin: 3px 0;
}

/* Mobile-Friendly */
@media (max-width: 768px) {
  .trending-list {
    flex-direction: column;
    align-items: center;
  }

  .custom-card {
    width: 139px !important; /* Fixed width for uniformity */
  }

  .trending-button {
    width: 100%;
    text-align: center;
  }
}
