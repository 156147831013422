.container.custom_className {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh; /* Adjusts vertical centering */
  padding: 20px;
}

.signup_title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2563eb;
  margin-bottom: 1.5rem;
}

.signup_form {
  width: 100%;
  max-width: 400px; /* Limits width on larger screens */
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.signup_form .form-outline {
  margin-bottom: 1.5rem;
}

.signup_form .form-control {
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.signup_form .btn-primary {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  background-color: #2563eb;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.signup_form .btn-primary:hover {
  background-color: #1d4ed8;
}

@media (max-width: 576px) {
  .container.custom_className {
    padding: 10px;
  }

  .signup_title {
    font-size: 1.5rem;
  }

  .signup_form {
    padding: 15px;
  }
}
